import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NB_AUTH_OPTIONS, NbAuthService, NbRegisterComponent, NbAuthResult } from '@nebular/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { NgModel, NgForm } from '@angular/forms';
import { User } from '../../_models/user.model';
import { DOCUMENT } from '@angular/common';
import { takeWhile } from 'rxjs/operators';
import { UserService } from '../../_services/user.service';
import { BusinessService } from '../../_services/business.service';
import { Business } from '../../_models/business.model';
import { OperatorService } from '../../_services/operator.service';
import { ClientService } from '../../_services/client.service';
import { CountryISO } from 'ngx-intl-tel-input';

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', '../auth.component.scss'],
})
export class RegisterComponent extends NbRegisterComponent implements OnInit {
  user: any;
  dniOrCuit: string = 'DNI';
  then: string;
  socialAlive = true;
  businessUser: boolean = false;
  serverErrors: any;
  business: Business;

  CountryISO = CountryISO;

  @ViewChild('email', { static: false }) email: NgModel;
  @ViewChild('dni', { static: false }) dni: NgModel;
  @ViewChild('form', { static: false }) form: NgForm;

  constructor(protected service: NbAuthService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              protected cd: ChangeDetectorRef,
              protected router: Router,
              protected route: ActivatedRoute,
              protected userService: UserService,
              protected operatorService: OperatorService,
              protected clientService: ClientService,
              protected businessService: BusinessService,
              @Inject(DOCUMENT) private document: Document) {
    super(service, options, cd, router);
    this.then = this.route.snapshot.params.then;
  }


  ngOnInit(): void {
    this.user.gender = '0';
    this.business = this.businessService.business;

    if (this.business.login_with_dni_text) {
      this.dniOrCuit = 'DNI';
    } else if (this.business.login_with_cuit_text) {
      this.dniOrCuit = 'CUIT';
    }

    this.route.data
      .subscribe((data) => {
        if (data.user) {
          if (data.user.phone) this.user.phone = data.user.phone;

          this.businessUser = data.user.role === 'business';

          this.user = {...data.user, gender: this.user.gender};
          if (data.user.birthday) {
            this.user.birthday = new Date(data.user.birthday);
          }
          if (this.user.first_name.indexOf(' ') > 0 && this.user.last_name === '') {
            [this.user.first_name, this.user.last_name] = this.user.first_name.split(' ', 2);
          }
        }
      });

  }

  register(): void {
    this.errors = this.messages = [];
    this.submitted = true;

    const data: any = {
      user: this.user.birthday ? {...this.user, birthday: this.user.birthday.toISOString().split('T')[0]} : this.user,
    };

    data.user.phone = this.user.phone ? this.user.phone.e164Number : null;

    if (this.then) {
      data.then = this.then;
    }

    this.service
      .register(this.strategy, data)
      .subscribe((result: NbAuthResult) => {
        if (result.isSuccess()) {
          this.messages = result.getMessages();

          const user = new User(result.getResponse().body);

          if (user.role === 'business') {
            this.document.location.href = `${user.business.link}/auth/login;status=verified`;
          } else {
            const params: any = {};

            if (this.then) {
              params.then = this.then;
            }

            if (data.user.id) {
              // Update user (login)
              this.service
                .authenticate(this.strategy, this.user)
                .subscribe((result2) => {
                  this.userService.clear();
                  this.operatorService.clear();
                  this.clientService.clear();
                  this.businessService.clear();

                  if (result2.isSuccess()) {
                    this.messages = result2.getMessages();
                    if (this.then) {
                      this.router.navigate([this.then]);
                    } else {
                      this.router.navigate(['/']);
                    }
                  }
              });
            } else {
              // New user (redirect)
              params.status = 'verification_pending';
              this.router.navigate(['/auth/login', params]);
            }
          }

        } else {
          this.submitted = false;
          this.errors = result.getErrors();
          this.serverErrors = result.getResponse().error.errors;
          Object
            .keys(this.serverErrors)
            .forEach((key) => {
              const message = this.serverErrors[key][0];
              const control = this.form.controls[key];

              if (control) {
                control.setErrors({ server: message });
              }

              this.errors = [];

            });

        }
      });
  }

  socialLogin(social) {
    localStorage.setItem('social_redirect_uri', this.route.snapshot.params.then);

    this.service
      .authenticate(social)
      .pipe(takeWhile(() => this.socialAlive))
      .subscribe((authResult: NbAuthResult) => {
      });
  }

  filterNumbers(event: any, invert = false) {
    if (event.key === 'Backspace') return;
    const test = /[0-9]/.test(event.key);
    if ((!invert && test) || (invert && !test)) event.preventDefault();
  }

}
